import React, {useEffect, useState} from 'react'
import ResultText from './result-text'

const ResultBox = props => {
  const [results, setResults] = useState([])

  useEffect(() => {
    if (props.result !== undefined) {
      setResults(Array.isArray(props.result) ? props.result : [props.result || ''])
    } else {
      const data = props.data || []
      const slugs = Array.isArray(props.slug) ? props.slug : [props.slug]

      const matches = data.filter(row => row && slugs.indexOf(row.division.slug) > -1)
      setResults(slugs.map(s => {
        const seq = props.seq || 0
        const found = matches.find(row => row && row.division.slug === s && (row.draw_seq || 0) === seq)
        if (!found) { return '' }
        return found.result
      }))
    }
  }, [props.data, props.slug, props.result, props.seq])

  const [variants, setVariants] = useState([])
  useEffect(() => {
    setVariants((props.variant || '').split(' '))
  }, [props.variant])

  const is = name => variants.find(p => p === name)

  return <>
    {results.map((r, i) => <div key={i}><ResultText yeekeeRun={props.yeekeeRun} result={r} digits={props.digits || 2} blink={props.blink} /></div>)}
  </>
}

export default ResultBox
