import ResultBox from './result-box'
import React from 'react'

const Lotto4dPrizeBox = (props) => {
  const {name, headerBgColor, headerColor, no, date, items, blink, num, logoSrc, logoSrcSet, logoStyle, schedule} = props

  const getResult  = idx => (items && items[idx]) || ''

  return <>
    <div className="p-1 border bg-white d-flex flex-grow-1 flex-column justify-content-between">

      <div className="header d-flex align-items-center" style={{backgroundColor: headerBgColor, color: headerColor}}>
        <img className="mx-2" src={logoSrc} srcSet={logoSrcSet} style={logoStyle} />
        <h2 className="flex-grow-1 mb-0 h4 text-center">{name}</h2>
      </div>

      <div className="prize">
        <div className="d-flex justify-content-between align-items-center small py-2">
          <span className="">Date: {date}</span> <span>Draw No: {no}</span>
        </div>

        <ul className="list-group overflow-hidden mb-1">
          <li className="list-group-item p-0">
            <div className="">
              <div className="d-flex justify-content-between">
                <span className="h5 mb-0 w-50 pl-2 flex-grow-1 d-flex align-items-center bg-dark text-white">1st Prize 首獎</span>
                <span className="h3 mb-0 w-50 flex-grow-1 text-center">
                  <ResultBox blink={blink} digits={4} result={getResult(0)} />
                </span>
              </div>
            </div>
          </li>
          <li className="list-group-item p-0">
            <div className="">
              <div className="d-flex justify-content-between">
                <span className="h5 mb-0 w-50 pl-2 flex-grow-1 d-flex align-items-center bg-dark text-white">2nd Prize 二獎</span>
                <span className="h3 mb-0 w-50 flex-grow-1 text-center">
                  <ResultBox blink={blink} digits={4} result={getResult(1)} />
                </span>
              </div>
            </div>
          </li>
          <li className="list-group-item p-0">
            <div className="">
              <div className="d-flex justify-content-between">
                <span className="h5 mb-0 w-50 pl-2 flex-grow-1 d-flex align-items-center bg-dark text-white">3rd Prize 三獎</span>
                <span className="h3 mb-0 w-50 flex-grow-1 text-center">
                  <ResultBox blink={blink} digits={4} result={getResult(2)} />
                </span>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div className="special">

        <table className="table table-bordered">
          <thead>
          <tr>
            <th className="bg-dark text-white" scope="col" colSpan="5">Special 特別獎</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            {[0, 1, 2, 3, 4].map(i => (<td key={i}>
              <ResultBox blink={blink} digits={4} result={getResult(i + 3)} />
            </td>))}
          </tr>
          <tr>
            {[5, 6, 7, 8, 9].map(i => (<td key={i}>
              <ResultBox blink={blink} digits={4} result={getResult(i + 3)} />
            </td>))}
          </tr>
          {num === 26 ? <tr>
            <td className="invisible --left">----</td>
            {[10, 11, 12].map(i => (<td key={i}>
              <ResultBox blink={blink} digits={4} result={getResult(i + 3)} />
            </td>))}
            <td className="invisible --right">----</td>
          </tr> : null}
          </tbody>
        </table>
      </div>

      <div className="consolation mt-auto">
        <table className="table table-bordered">
          <thead>
          <tr>
            <th className="bg-dark text-white" scope="col" colSpan="5">Consolation 安慰獎</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            {[0, 1, 2, 3, 4].map(i => <td key={i}>
              <ResultBox blink={blink} digits={4} result={getResult(i + num - 10)} />
            </td>)}
          </tr>
          <tr>
            {[5, 6, 7, 8, 9].map(i => <td key={i}>
              <ResultBox blink={blink} digits={4} result={getResult(i + num - 10)} />
            </td>)}
          </tr>
          </tbody>
        </table>

      </div>

      <div className="p-1">
        Draw Schedule: {schedule}
      </div>

    </div>
  </>
}

export default Lotto4dPrizeBox
