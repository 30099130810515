import React, {useEffect, useState} from 'react'
import ResultTextDigits from './result-text-digits'

export default function ResultTextRunner ({result, yeekeeRun}) {
  const [current, setCurrent] = useState(result)
  const timers = []

  const isRolling = (c) => c === '*'

  useEffect(() => {
    if (process.env.GATSBY_YEEKEE_RUN_MODE !== 'client' || !yeekeeRun) {
      setCurrent(result)
      return
    }

    let maxTimeoutSec = (current.split('').filter((c) => isRolling(c)).length - 1) * 10000

    let next = current.split('')
    result.split('').forEach((c, i) => {
      next[i] = c

      if (isRolling(current[i]) && !isRolling(c)) {
        const t = setTimeout(() => {
          next[i] = c
          setCurrent(next.join(''))
        }, maxTimeoutSec)
        maxTimeoutSec -= 10000
        timers.push(t)
        next[i] = '*'
      }
    })
    setCurrent(next.join(''))

    return () => {
      timers.forEach((t, idx) => {
        clearTimeout(t)
        delete timers[idx]
      })
    }
  }, [result, yeekeeRun])

  return <>
    <ResultTextDigits result={current} />
  </>
}
