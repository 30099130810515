import React, {useEffect, useState} from 'react'
import ResultTextRunner from './result-text-runner'

const ResultText = ({result, digits, yeekeeRun, blink}) => {
  const [isBlank, setIsBlank] = useState(true)
  const [isBlink, setIsBlink] = useState(true)

  let r = result
  useEffect(() => {
    r = (result || '').toString().trim()

    let newIsBlank = r.length === 0
    setIsBlank(newIsBlank)
    setIsBlink(blink && isBlank && !newIsBlank)
  }, [result])

  return <div
    className={
      `${isBlank ? 'result-blank' : ''} ${isBlink ? 'blink' : ''}`
    }>
    {isBlank ? ''.padStart(digits, '-') : <ResultTextRunner yeekeeRun={yeekeeRun} result={r} />}
  </div>
}

export default ResultText
