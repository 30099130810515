import React, {useEffect, useState} from 'react'

const getRandomNumber = digits => {
  return Math.floor(Math.random() * Math.pow(10, digits))
    .toString()
    .padStart(digits, '0')
}

const getUnduplicatedRandomNumber = (digits, ids, index) => {
  const lastRandom = ids[index]
  let newRandom = getRandomNumber(digits)
  if (newRandom === lastRandom) {
    console.debug('-> oops dup!', newRandom)
    newRandom = getRandomNumber(digits)
  }
  if (newRandom === lastRandom) {console.debug('-> still dup!', newRandom)}
  ids[index] = newRandom
  return newRandom
}

const isRolling = (c) => c.indexOf('*') > -1

const getRenderChars = (groups, ids) => {
  return groups.map((group, i) => isRolling(group) ? getUnduplicatedRandomNumber(group.length, ids, i) : group).join('')
}

export default function ResultTextDigits ({result}) {
  const [renderChars, setRenderChars] = useState(getRenderChars([result], {}))

  useEffect(() => {
    if (!isRolling(result)) {
      setRenderChars(result)
      return
    }

    let ids = {}

    const firstDigitIdx = result.lastIndexOf('*')
    const groups = [
      result.slice(0, firstDigitIdx + 1),
      result.slice(firstDigitIdx + 1),
    ]
    groups.forEach((g, i) => ids[i] = '')

    const t = setInterval(() => {
      setRenderChars(getRenderChars(groups, ids))
      if (!isRolling(result)) {
        clearInterval(t)
      }
    }, 150)
    return () => clearInterval(t)
  }, [result])

  useEffect(() => {}, [renderChars])

  return (
    <>
      {result.split('').map((c, i) => (
        <span key={i} className={isRolling(c) ? 'text-danger' : ''}>
          {renderChars[i]}
        </span>
      ))}
    </>
  )
}
