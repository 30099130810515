import React, {useCallback, useEffect, useRef, useState} from 'react'
import ky from 'ky'
import dayjs from 'dayjs'
import 'dayjs/locale/th'
import buddhistEra from 'dayjs/plugin/buddhistEra'
import isBetween from 'dayjs/plugin/isBetween'
import utc from 'dayjs/plugin/utc'
import './index.css';
import Lotto4dPrizeBox from '../components/lotto4d-prize-box'
import {Helmet} from 'react-helmet/es/Helmet'
import {InputGroup, Form} from 'react-bootstrap'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCalendarAlt} from '@fortawesome/free-regular-svg-icons'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'

dayjs.extend(buddhistEra)
dayjs.extend(isBetween)
dayjs.extend(utc)

const prizes = [
  {slug: 'magnum4d', total: 26, hour: 18, min: 30, name: 'Magnum 4D 萬能', schedule: 'Wed, Sat, Sun 18:15'},
  {slug: 'genting4d', total: 26, hour: 18, min: 30, name: 'Genting 4D', schedule: 'Everyday 18:15'},
  {slug: 'singapore4d', total: 23, excluded: ['singapore4d-3-up', 'singapore4d-2-down'], hour: 17, min: 30, name: 'Singapore 4D', schedule: 'Wed, Sat, Sun 17:15'},
  {slug: 'macau4d', total: 23, hour: 17, min: 30, name: 'Macau 4D', schedule: 'Everyday 21:15'},
  {slug: 'sands4d', total: 23, hour: 20, min: 30, name: 'Sands 4D', schedule: 'Everyday 13:15'},
  {slug: 'kk4d', total: 26, hour: 18, min: 30, name: 'KK 4D', schedule: 'Everyday 16:15'},
]

const getDefaultData = () => {
  return {
    'magnum4d': {},
    'genting4d': {},
    'singapore4d': {},
    'macau4d': {},
    'sands4d': {},
    'kk4d': {},
  }
}

const getData = async (date) => {
  const dateStr = dayjs(date).format('YYYY-MM-DD')
  try {
    const res = await ky.get(`${process.env.GATSBY_API_URL}/summary?profile_name=lotto4d&params[date]=${dateStr}`, {}).json()
    return JSON.parse(res.data)
  } catch (e) {
    console.error(e)
    return null
  }
}

export default () => {
  const [date, setDate] = useState(new Date())
  // split line to prevent date and data confusion
  const [data, setData] = useState(getDefaultData())
  const [loaded, setLoaded] = useState(false)
  const [isInit, setIsInit] = useState(false)

  const reloadData = () => {
    getData(date).then(data => {
      if (data) {
        setData(data)
      }
    })
    setLoaded(true)
  }
  useEffect(() => {
    reloadData()
    const t = setInterval(reloadData, 2500)
    return () => clearInterval(t)
  }, [date])

  useEffect(() => {
    setTimeout(() => {
      if (!isInit) setIsInit(true)
    }, 500)
  }, [loaded])

  const [hourNow, setHourNow] = useState('')
  useEffect(() => {
    setInterval(() => {
      setHourNow(dayjs().utcOffset(7).format('HH:mm:ss'))
    }, 1000)
  }, [])

  const [dayPickerVisible, setDayPickerVisible] = useState(false)
  const dayPicker = useRef()
  const toggleDayPicker = () => {
    setDayPickerVisible(!dayPickerVisible)
  }
  const [modifiers, setModifiers] = useState({})
  useEffect(() => {
    setModifiers({
      disabled: {
        before: dayjs().subtract(30, 'day').toDate(),
        after: dayjs().toDate(),
      },
      selected: dayjs(date).toDate()
    })
  }, [date])

  const handleDayClick = (day, {disabled}) => {
    if (!disabled) {
      setDate(day)
      setDayPickerVisible(false)
    }
  }
  const backdrop = useCallback(node => {
    if (node != null) {
      node.addEventListener('click', () => setDayPickerVisible(false))
    }
  })

  return (
    <>
      <Helmet script={[
        {src: 'https://www.googletagmanager.com/gtag/js?id=UA-167870612-1', async: true},
        {innerHTML: `window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments)}
        gtag('js', new Date());
        gtag('config', 'UA-167870612-1'); `},
      ]} />
      <nav className="navbar navbar-expand-md navbar-dark shadow">
        <div className="container">
          <a className="navbar-brand" href="#">
            <img className={`d-block`} src={require('../images/logo-lotto4d.png')} alt="Lotto 4D"
                 srcSet={`${require('../images/logo-lotto4d@2x.png')} 2x, ${require('../images/logo-lotto4d.png')} 1x`} />
          </a>
          <div className="clock ml-auto text-white text-right border rounded-pill py-1 px-2">
            <span className="d-flex align-items-center justify-content-center font-weight-bold">{hourNow}</span>
          </div>
        </div>
      </nav>
      <main role="main" className="container-xl container-fluid pt-4">

        <h1 className="sr-only">Lotto4D</h1>

        <div className="text-md-right text-sm-center col-md-8 col-12 mb-3">
          <div className="d-inline-block date-filter">
            <div className="d-flex flex-wrap align-items-center">
              <div className="mr-3 text-white">Select Date:</div>
              <div className="position-relative">
                <InputGroup onClick={toggleDayPicker}>
                  <Form.Control
                    type="text"
                    readOnly
                    value={dayjs(date).format('DD MMM YYYY')}
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>
                      <FontAwesomeIcon icon={faCalendarAlt}/>
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
                <div
                  ref={dayPicker}
                  className={`position-absolute shadow border-color-grey rouneded bg-white w-100 daypicker-wrapper ${
                    dayPickerVisible ? 'd-block' : 'd-none'
                  }`}
                >
                  <DayPicker
                    onDayClick={handleDayClick}
                    modifiers={modifiers}
                  />
                </div>
                <div
                  ref={backdrop}
                  className={`position-fixed w-100 h-100 bg-black ${
                    dayPickerVisible ? 'd-block' : 'd-none'
                  }`}
                  style={{top: 0, left: 0, opacity: 0.6, zIndex: 20}}
                ></div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">

          {/* sands4d */}
          <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
            <Lotto4dPrizeBox name={prizes[4].name} num={prizes[4].total} items={data['sands4d'].items} no={data['sands4d'].no} blink={isInit}
                             date={data['sands4d'].date}
                             headerBgColor="#B3965A"
                             headerColor="white"
                             logoSrc={require('../images/logo_sands-2.png')}
                             logoStyle={{height: 36}}
                             schedule={prizes[4].schedule}
            >
            </Lotto4dPrizeBox>
          </div>

          {/* singapore */}
          <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
            <Lotto4dPrizeBox name={prizes[2].name} num={prizes[2].total} items={data['singapore4d'].items} no={data['singapore4d'].no} blink={isInit}
                             date={data['singapore4d'].date}
                             headerBgColor="#0098e6"
                             headerColor="white"
                             logoSrc={require('../images/logo_singapore.png')}
                             schedule={prizes[2].schedule}
            >
            </Lotto4dPrizeBox>
          </div>

          {/* kk */}
          <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
            <Lotto4dPrizeBox name={prizes[5].name} num={prizes[5].total} items={data['kk4d'].items} no={data['kk4d'].no} blink={isInit}
                             date={data['kk4d'].date}
                             headerBgColor="#d93934"
                             headerColor="white"
                             logoSrc={require('../images/logo-kk4d.png')}
                             logoSrcSet={`${require('../images/logo-kk4d.png')} 1x, ${require('../images/logo-kk4d@2x.png')} 2x`}
                             logoStyle={{height: 36}}
                             schedule={prizes[5].schedule}
            >
            </Lotto4dPrizeBox>
          </div>

          {/* magnum4d */}
          <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
            <Lotto4dPrizeBox name={prizes[0].name} num={prizes[0].total} items={data['magnum4d'].items} no={data['magnum4d'].no} blink={isInit}
                             date={data['magnum4d'].date}
                             headerBgColor="#ff0"
                             logoSrc={require('../images/logo_magnum.gif')}
                             schedule={prizes[0].schedule}
            >
            </Lotto4dPrizeBox>
            {/*<div className="ads text-center mt-3 py-4 px-2 border bg-white">*/}
            {/*  <h2>ads</h2>*/}
            {/*</div>*/}
          </div>

          {/* genting */}
          <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
            <Lotto4dPrizeBox name={prizes[1].name} num={prizes[1].total} items={data['genting4d'].items} no={data['genting4d'].no} blink={isInit}
                             date={data['genting4d'].date}
                             headerBgColor="#dd1212"
                             headerColor="white"
                             logoSrc={require('../images/logo-genting-white.png')}
                             logoSrcSet={`${require('../images/logo-genting.png')} 1x, ${require('../images/logo-genting-white@2x.png')} 2x`}
                             logoStyle={{width: 76}}
                             schedule={prizes[1].schedule}
            >
            </Lotto4dPrizeBox>
          </div>

          {/* macau */}
          <div className="col-12 col-sm-6 col-lg-4 mb-4 d-flex flex-column">
            <Lotto4dPrizeBox name={prizes[3].name} num={prizes[3].total} items={data['macau4d'].items} no={data['macau4d'].no} blink={isInit}
                             date={data['macau4d'].date}
                             headerBgColor="#01785e"
                             headerColor="white"
                             logoSrc={require('../images/logo_macau.png')}
                             logoSrcSet={`${require('../images/logo_macau.png')} 1x, ${require('../images/logo_macau@2x.png')} 2x`}
                             schedule={prizes[3].schedule}
            >
            </Lotto4dPrizeBox>
          </div>


        </div>
      </main>
    </>
  )
}
